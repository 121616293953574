import { i18n } from "@/main";
const t = i18n.global.t;
export default class OrganizationsTableData {
    static getColumns() {
        return [
            // {
            //   name: "id",
            //   align: "center",
            //   label: t("shared.id"),
            //   field: "id",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
            {
                name: "name",
                align: "center",
                label: t("shared.organizationName"),
                field: "name",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 40%",
                headerStyle: "width: 40%",
            },
            {
                name: "description",
                align: "center",
                label: t("shared.description"),
                field: "description",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 40%",
                headerStyle: "width: 40%",
            },
            {
                name: "isActive",
                align: "center",
                label: t("shared.isActive"),
                field: "isActive",
                format: (val) => `${val ? t("shared.active") : t("shared.inactive")}`,
                sortable: false,
                isBoolean: true,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "action-details",
                align: "left",
                label: "",
                field: "action-details",
                format: (val) => `${val ?? ""}`,
                sortable: false,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            // {
            //   name: "action-delete",
            //   align: "center",
            //   label: "",
            //   field: "action-edit",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            // },
        ];
    }
}
